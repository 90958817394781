import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { USD } from '../../helpers/NumberHelper'
import { uniformUrl } from '../../helpers/StringHelpers'
import { ProductTypeHelper } from '../../helpers/ProductTypeHelper'
import { ProductShortTitle } from '../products/ProductTitle'
import CategoryImage from '../CategoryImage'
import { IconHeart } from '../popups/FavoriteItem'
import { AllowedTags } from '../products/Tags'
import finalPrice from '../../helpers/product/finalPrice'

const ProductSliderCard = ({ product, tags = true, openFavorite = null }) => {
  const isSale = product?.special_price === null
  const url = uniformUrl((product.url || product.urlPath) + '/')
  const onClick = useCallback(
    e => {
      e.preventDefault()
      if (openFavorite) openFavorite(product)
    },
    [product, openFavorite]
  )
  const productType = product.productType || ProductTypeHelper(product)
  const isWatch = productType.endsWith('Watch')
  return (
    <Link
      to={url}
      className="group bg-white relative my-2 flex w-full flex-col items-center justify-center gap-2 rounded-md border p-4 shadow-lg duration-300 ease-out hover:scale-[102%]"
    >
      <button onClick={onClick}>
        <IconHeart className="absolute top-2 right-2 z-10 h-8 w-8 rounded-full bg-white p-[.3rem] text-gray-200 duration-700 group-hover:text-red-500" />
      </button>

      <CategoryImage
        sku={product.sku}
        alt={product.name}
        title={product.title}
        normal={product.small_image}
        productType={productType}
        tags={tags}
        imgStyle={{ objectFit: 'contain' }}
        product={product}
        className={'aspect-square w-full h-full'}
      />
      <div className="flex flex-col items-center text-center">
        <h3
          className={
            ' h-8 md:h-12 overflow-hidden text-xs md:text-base font-bold uppercase line-clamp-2'
          }
        >
          {ProductShortTitle(product)}
          {isWatch ? (
            <span className="text-gray-500 inline-block w-full line-clamp-1">{product.model}</span>
          ) : null}
        </h3>

        <div
          className={
            (isSale ? 'text-red-700' : '') +
            ' hidden md:block mx-2 my-3 text-xl font-bold text-gray-700'
          }
        >
          {USD(finalPrice(product))}
        </div>
        <div className="red-raised-btn my-2 px-3 py-2 text-sm md:text-base">SHOP NOW</div>
      </div>
    </Link>
  )
}

// create prop types validation for ProductSliderCard
ProductSliderCard.propTypes = {
  product: PropTypes.object.isRequired,
  openFavorite: PropTypes.func,
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOf(AllowedTags))]),
}

export default ProductSliderCard
