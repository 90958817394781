import React, { useState } from 'react'
import ProductSliderCard from './ProductSliderCard'
import PropTypes from 'prop-types'
import Slider from './Slider'
import { BlogSliderButtonNext, BlogSliderButtonPrev } from './slider/BlogSliderButton'
import { AllowedTags } from '../products/Tags'
import FavoriteItems from '../popups/FavoriteItem'
import useMediaQuery from '../../hooks/useMediaQuery'

export const ProductSliderItemsPerScreen = step => {
  // Assuming useMediaQuery is a custom hook or imported from a library
  const isMd = useMediaQuery('(min-width: 48em)')
  const isLg = useMediaQuery('(min-width: 64em)')
  const isXl = useMediaQuery('(min-width: 80em)')
  const theStep = (step || 0) <= 0 ? Number.MAX_SAFE_INTEGER : step

  const itemsPerScreen = React.useCallback(() => {
    if (isXl) return Math.min(4, theStep)
    if (isLg) return Math.min(3, theStep)
    return Math.min(2, theStep)
  }, [isXl, isLg, isMd, theStep])

  return [itemsPerScreen, 'w-1/2 lg:w-1/3 xl:w-1/4']
}

const ProductSlider = ({
  h2Title,
  products,
  tags = true,
  step = 0,
  className = 'md:py-10',
  itemsPerScreenFactory = ProductSliderItemsPerScreen,
}) => {
  const [fav, openFavorite] = useState(null)

  return (
    <section className={'max-w-[1366px] mx-auto ' + className}>
      {h2Title && <h2 className="underline-title">{h2Title}</h2>}

      <Slider
        buttonPrev={BlogSliderButtonPrev}
        buttonNext={BlogSliderButtonNext}
        childrenClassName={'flex-none snap-start p-2 md:pb-10 z-0'}
        className={'sm:p-6'}
        step={step}
        itemsPerScreenFactory={itemsPerScreenFactory}
      >
        {products.map(product => (
          <ProductSliderCard
            key={product.sku}
            product={product}
            tags={tags}
            openFavorite={openFavorite}
          />
        ))}
      </Slider>

      {fav && (
        <FavoriteItems
          className="absolute top-2 right-2 z-10"
          product={fav}
          setFavorite={openFavorite}
        />
      )}
    </section>
  )
}

ProductSlider.propTypes = {
  h2Title: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOf(AllowedTags))]),
  step: PropTypes.number,
  className: PropTypes.string,
  itemsPerScreenFactory: PropTypes.func,
}

export default ProductSlider
