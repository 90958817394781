// src/helpers/CryptoHelper.js
import { useState, useEffect } from 'react'

// Cache and promise references
let cachedRate = null
let fetchPromise = null

export const useCryptoConversionRate = (cryptoSymbol = 'btc', fiat = 'usd') => {
  const [rate, setRate] = useState(cachedRate)

  useEffect(() => {
    // If rate is already cached, use it directly
    if (cachedRate) {
      return
    }

    // If no fetch is in progress, start one
    if (!fetchPromise) {
      fetchPromise = fetch(`https://bitpay.com/rates/${cryptoSymbol.toLowerCase()}/${fiat.toLowerCase()}`)
        .then((response) => response.json())
        .then((data) => {
          cachedRate = data.data.rate
          fetchPromise = null  // reset so we can fetch again in a new session, if needed
          setRate(cachedRate)
        })
        .catch((err) => {
          console.error('Error fetching crypto conversion rate:', err)
          fetchPromise = null
        })
    } else {
      // A fetch is in progress, so just attach a then-callback
      fetchPromise.then(() => {
        // once fetchPromise resolves, it should have populated cachedRate
        setRate(cachedRate)
      })
    }
  }, [cryptoSymbol, fiat])

  return rate
}
