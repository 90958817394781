import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { ProductSliderItemsPerScreen } from './ProductSlider'

import { AllowedTags, TAG_SALE, TAG_SELLABLE } from '../products/Tags'
import PropTypes from 'prop-types'
import DotSlider from './DotSlider'
import FavoriteItems, { IconHeart } from '../popups/FavoriteItem'
import CategoryImage from '../CategoryImage'
import { ProductShortTitle } from '../products/ProductTitle'
import USD from '../../helpers/NumberHelper'
import { ProductTypeHelper } from '../../helpers/ProductTypeHelper'
import { uniformUrl } from '../../helpers/StringHelpers'
import { IconChevronRight } from '../Icons'
import {
  IconBracelet,
  IconDiamond,
  IconMensWatch,
  IconSet,
} from '../products/IconsProductPreloader'
import finalPrice from '../../helpers/product/finalPrice'
import CategoryImageSimplified from '../CategoryImageSimplified'
import { CryptoPrice } from '../products/CryptoPrice'

const NewArrivalsProductSliderCardSeeAll = ({ className, url, name = 'New Arrivals' }) => {
  return (
    <Link
      to={url || '/what-s-new/'}
      className={
        className +
        ' my-4 group relative  flex flex-col shrink-0 grow-0 items-center justify-center gap-2 rounded-md border-2 p-2 mx-2 shadow-md duration-300 ease-out lg:hover:scale-[102%] max-w-[250px] bg-red-700 text-white'
      }
    >
      <div className="grid grid-cols-2 grid-rows-2 p-3">
        <IconMensWatch flashing={false} className="w-full aspect-square stroke-1 stroke-white" />
        <IconSet flashing={false} className="w-full aspect-square " />
        <IconBracelet flashing={false} className="w-full aspect-square stroke-1 stroke-white" />
        <IconDiamond flashing={false} className="w-full aspect-square stroke-1 stroke-white" />
      </div>
      <p className="text-xs xxs:text-base md:text-xl font-black uppercase">
        Shop All
        <br />
        {name || 'New Arrivals'}
        <IconChevronRight className="stroke-2 stroke-white p-[.1rem] translate-y-[.1rem] ml-1" />
      </p>
    </Link>
  )
}
NewArrivalsProductSliderCardSeeAll.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
}
export const NewArrivalsProductSliderCard = ({
  product,
  tags = true,
  openFavorite = null,
  className,
  showShopButton = true,
  eager = false,
  currency = 'USD'
}) => {
  const isSale = product?.special_price !== null
  const url = uniformUrl((product.url || product.urlPath) + '/')
  const onClick = React.useCallback(
    e => {
      e.preventDefault()
      if (openFavorite) openFavorite(product)
    },
    [product, openFavorite]
  )
  const productType = product.productType || ProductTypeHelper(product)
  const isWatch = productType.endsWith('Watch')
  const final_price = finalPrice(product)
  return (
    <Link
      to={url}
      className={
        ' my-4 group bg-white relative  flex flex-col shrink-0 grow-0 items-center justify-center gap-2 rounded-md border p-2 mx-2 shadow-md duration-300 ease-out lg:hover:scale-[102%] max-w-[250px] ' +
        className
      }
    >
      {openFavorite && (
        <button className="hover:text-red-500 text-gray-200" onClick={onClick}>
          <IconHeart className="absolute top-2 right-2 z-10 h-8 w-8 rounded-full bg-white p-[.3rem]  duration-700 " />
        </button>
      )}

      <CategoryImageSimplified
        image={product.small_image}
        loading={eager ? 'eager' : 'lazy'}
        product={product}
      />
      <div className={(!showShopButton && 'pb-6') + ' flex flex-col items-center text-center '}>
        <h3
          className={
            (isWatch ? 'h-4 md:h-6' : ' h-8 md:h-12 ') +
            ' text-xs md:text-base font-bold uppercase line-clamp-2 whitespace-normal text-gray-500 text-ellipsis w-full '
          }
        >
          {ProductShortTitle(product)}
        </h3>
        {isWatch ? (
          <h4 className="text-gray-500 inline-block w-full line-clamp-1 text-ellipsis ">
            {product.model}
          </h4>
        ) : null}

        <div
          className={
            (isSale ? 'text-red-700' : '') +
            ' hidden md:block mx-2 my-1 text-xl font-bold text-gray-700'
          }
        >
          { (!currency || currency === 'USD') ? (
            USD(final_price)
          ) : (
            <CryptoPrice usdPrice={final_price} cryptoSymbol={currency} />
          )}
        </div>
        <div
          className={
            (showShopButton
              ? 'static '
              : 'lg:absolute -bottom-14 group-hover:-bottom-5 lg:opacity-0 lg:group-hover:opacity-100 ') +
            ' rounded-md border-2 border-red-700 bg-red-700 text-center font-bold text-white duration-200 hover:-translate-y-1 hover:border-red-700 hover:bg-white hover:text-red-700 hover:shadow-lg my-2 px-3 py-2 text-sm md:text-base '
          }
        >
          SHOP NOW
        </div>
      </div>
    </Link>
  )
}
NewArrivalsProductSliderCard.propTypes = {
  product: PropTypes.object.isRequired,
  openFavorite: PropTypes.func,
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOf(AllowedTags))]),
  className: PropTypes.string,
  showShopButton: PropTypes.bool,
  eager: PropTypes.bool,
}

const NewArrivalSlider = ({
  step = 0,
  className = ' w-full md:py-10',
  itemsPerScreenFactory = ProductSliderItemsPerScreen,
  eager = false,
  currency = 'USD'
}) => {
  const data = useStaticQuery(graphql`
    query newArrivals {
      products: allStrapiProduct(
        filter: { categories: { elemMatch: { name: { eq: "New Arrivals" } } }, qty: { gt: 0 } }
        sort: { fields: stock_date, order: DESC }
        limit: 11
      ) {
        nodes {
          name
          productType
          categories {
            name
            url: urlPath
            strapi_id
          }
          url: urlPath
          sku
          title
          qty
          small_image {
            name
            gatsbyImageDataMock
          }
          thumbnail {
            name
            gatsbyImageDataMock
          }
          brand
          model
          price
          special_price
        }
      }
    }
  `)
  const [fav, openFavorite] = React.useState(null)
  const [perScreenFactory, linkClassName] = itemsPerScreenFactory(step)
  const sections = perScreenFactory(step)
  return (
    <section className={'relative max-w-[1366px] w-full mx-auto ' + className}>
      <h2>
        <Link className="underline-title" to="/what-s-new">
          Latest Arrivals
        </Link>
      </h2>

      <DotSlider dots={Math.ceil(data.products.nodes.length / sections)}>
        {data.products.nodes.map((product, index) => (
          <NewArrivalsProductSliderCard
            key={product.sku}
            className={linkClassName}
            tags={[TAG_SALE, TAG_SELLABLE]}
            product={product}
            openFavorite={openFavorite}
            eager={eager && index < 4}
            currency={currency}
          />
        ))}
        <NewArrivalsProductSliderCardSeeAll className={linkClassName} url={'/what-s-new/'} />
      </DotSlider>

      {fav && (
        <FavoriteItems
          className="absolute top-2 right-2 z-10"
          product={fav}
          setFavorite={openFavorite}
        />
      )}
    </section>
  )
}
NewArrivalSlider.propTypes = {
  step: PropTypes.number,
  className: PropTypes.string,
  itemsPerScreenFactory: PropTypes.func,
  eager: PropTypes.bool,
}

export default NewArrivalSlider
