// src/helpers/CryptoHelper.js
import React from 'react'
import currency from 'currency.js'
import { useCryptoConversionRate } from '../../helpers/CryptoHelper'
import USD from '../../helpers/NumberHelper'
import PropTypes from 'prop-types'

/**
 * Component to display a USD price as its crypto equivalent.
 *
 * For example, if conversionRate is 2.653527 (i.e. 1 XRP = $2.65) then
 * a $10,000 item will be roughly 10000 / 2.653527 ≈ 3770 XRP.
 *
 * While the conversion rate is being fetched, a loading indicator is shown.
 */
export const CryptoPrice = ({ usdPrice, cryptoSymbol = 'BTC' }) => {
  // Note: We force the crypto symbol to lowercase when fetching, then use the original for display.
  const conversionRate = useCryptoConversionRate(cryptoSymbol.toLowerCase(), 'usd')

  if (!conversionRate) {
    return (
      <div>
        <div className="w-full bg-gray-200 h-6 skeleton" />
        <div className="text-sm text-gray">{USD(usdPrice)}</div>
      </div>
    )
  }

  const cryptoPrice = usdPrice / conversionRate
  const oneUsdCrypto = 1 / conversionRate

  // If 1 USD buys at least 0.01 coin, then no decimals are needed.
  // Otherwise, compute the number of decimals so that the smallest unit is roughly a 1 USD change.
  const precision = oneUsdCrypto >= 0.01 ? 0 : Math.ceil(-Math.log10(oneUsdCrypto)) + 2

  return (
    <div>
      <div className="flex flex-row gap-2 items-center justify-center">
        {currency(cryptoPrice, { precision, symbol: '', pattern: '# !' }).format()}
        <span className="text-white rounded-full px-2 p-1 bg-[#F7931A]">{cryptoSymbol}</span>
      </div>
      <div className="text-sm text-gray-400">{USD(usdPrice)}</div>
    </div>
  )
}

CryptoPrice.propTypes = {
  usdPrice: PropTypes.number.isRequired,
  cryptoSymbol: PropTypes.string,
}
